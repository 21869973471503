export const firebaseConfig = {
  apiKey: "AIzaSyCVC94zjvfasYoME_jY57piYZQsvbEfj_s",
  authDomain: "wonder-ocracy-staging.firebaseapp.com",
  databaseURL: "https://wonder-ocracy-staging.firebaseio.com",
  projectId: "wonder-ocracy-staging",
  storageBucket: "wonder-ocracy-staging.appspot.com",
  messagingSenderId: "455931249175",
  // This loads the Event planning page - Set to 2,3 to change it
  // Or leave it empty to show all the tasks as the default page
  defaultPageToLoad: '' //'?filter=taskType&text=3'
};
